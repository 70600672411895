import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {Divider, Layout, Text} from '@phonectas/unifon-ui-kit';
import {useTranslation} from 'react-i18next';
import eventTypeMapping from '../../eventTypeMapping';

function Events({events, show, handleScrollAction}) {
	const [t] = useTranslation();
	const handleScroll = () => {
		const scrollElement = document.getElementById('events_wrapper');
		if (scrollElement) {
			const {scrollTop, scrollHeight, clientHeight} = scrollElement;
			if (scrollTop + clientHeight >= scrollHeight - 1) {
				handleScrollAction();
			}
		}
	};

	useEffect(() => {
		const scrollElement = document.getElementById('events_wrapper');
		if (scrollElement) {
			scrollElement.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (scrollElement) {
				scrollElement.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	if (!events || !events?.feed_entries) {
		return (
			<Layout direction="column" gap="none" fullWidth fullHeight>
				<Text variant="body1">{t('No events found')}</Text>
			</Layout>
		);
	}

	return (
		<Layout
			fullWidth
			fullHeight
			direction="column"
			nowrap
			autoOverflow
			id="events_wrapper"
			style={{
				paddingRight: '5px',
				paddingBottom: show ? '20px' : '0px',
				// maxHeight: '100vh',
			}}
		>
			{events?.feed_entries?.map((event, index) => {
				const EventComponent = eventTypeMapping[event.feed_type];
				return EventComponent ? (
					<Layout gap="sm" direction="column" fullWidth nowrap key={index}>
						<EventComponent key={index} data={event} id={event.id} />
						{index !== events?.feed_entries.length - 1 && <Divider />}
					</Layout>
				) : (
					<></>
					// <div key={index}>Unknown event type: {event.feed_type}</div>
				);
			})}
		</Layout>
	);
}

Events.propTypes = {
	events: PropTypes.object,
	show: PropTypes.bool,
	handleScrollAction: PropTypes.func,
};

export default Events;
