import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {useCopyToClipboard} from 'common/hooks/useCopyToClipboard';
import {EmailIcon, Layout, Table, Text, Tooltip} from '@phonectas/unifon-ui-kit';
import {StyledSectionDiv} from '../../../styled';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import {LIMIT_PAYLOAD} from 'common/constants';


function ContactsTable({search, id}) {
	const [t] = useTranslation();
	// eslint-disable-next-line no-unused-vars
	const [isCopied, copyToClipboard] = useCopyToClipboard();
	const {contacts, totalCount, accountNotFound} = useThreeSixtyStore((state) => ({
		contacts: state.accountTables?.contacts,
		totalCount: state.accountTables?.contacts?.total_count,
		accountNotFound: state.accountNotFound,

	}));
	const contactsDate = contacts?.customer_contacts;
	const handleCopyEmail = useCallback((e, mail) => {
		e.stopPropagation();
		copyToClipboard(mail);
	}, [copyToClipboard]);
	const {fetchContacts} = useThreeSixtyStore();

	const onLoadMore = useCallback((size) => {
		if (totalCount <= size) return;
		fetchContacts(size + LIMIT_PAYLOAD, id);
	}, [id, totalCount]);

	const renderItem = useCallback((item, index) => (
		<Table.Row
			key={index}
			selected={item.index === index}
			index={index}
			expandedContent={
				<>
					{
						item?.note && (
							<Layout direction="column" fullWidth gap="xs">
								<Layout direction="row" gap="xs">
									<Text>{`${t('Note')}:`}</Text>
									<Text>{item.note}</Text>
								</Layout>
							</Layout>
						)
					}
				</>
			}
		>
			<Table.Cell>{item?.company_name}</Table.Cell>
			<Table.Cell>{item?.name}</Table.Cell>
			<Table.Cell>{item?.phone_number}</Table.Cell>
			<Table.Cell>
				<span
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
						cursor: 'pointer',
					}}
				>
					<Tooltip
						text={item?.email ? item?.email : ''}
						arrow
					>
						<span
							style={{cursor: 'pointer'}}
							onClick={(e) => handleCopyEmail(e, item?.email)}
						>
							<EmailIcon />
						</span>
					</Tooltip>
				</span>
			</Table.Cell>
			<Table.Cell>{item?.address}</Table.Cell>
			<Table.Cell>{item?.type}</Table.Cell>
			<Table.Cell>{item?.note ? 'note' : ''}</Table.Cell>
		</Table.Row>
	), []);

	useEffect(() => {
		if (accountNotFound) return;

		const isAccountIdMatched = contactsDate?.length > 0 && id === contactsDate[0]?.account_id;
		const isContactFetched = contacts !== null && isAccountIdMatched;
		const isContactsFilterMatched = contacts?.filters?.account_id === id && contactsDate === null;

		if (isContactFetched || isContactsFilterMatched) return;

		fetchContacts(LIMIT_PAYLOAD, id);
	}, [id, contacts, contactsDate, accountNotFound]);

	if (!contacts || !contacts?.customer_contacts) {
		return (
			<StyledSectionDiv $endDiv $fullHeight>
				<Layout
					fullHeight
					fullWidth
					alignItems="center"
					justifyContent="center"
				>
					<Text>{t('No data')}</Text>
				</Layout>
			</StyledSectionDiv>
		);
	}

	return (
		<StyledSectionDiv
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: '1',
				overflow: 'hidden',
			}}
			$endDiv
			$fullHeight
		>
			<Table
				style={{
					width: '100%',
					overflow: 'auto',
					height: '100%',
					borderRadius: '8px',
				}}
				items={contacts?.customer_contacts || []}
				renderItem={(item, index) => renderItem(item, index)}
				expandByClick
				virtualized
				onScrollDown={onLoadMore}
			>
				<Table.Row style={{position: 'sticky', top: '0px'}}>
					<Table.Cell>{t('Company')}</Table.Cell>
					<Table.Cell>{t('Name')}</Table.Cell>
					<Table.Cell>{t('Phone')}</Table.Cell>
					<Table.Cell width={45}>{t('Email')}</Table.Cell>
					<Table.Cell>{t('Address')}</Table.Cell>
					<Table.Cell>{t('Type')}</Table.Cell>
					<Table.Cell>{t('Note')}</Table.Cell>
				</Table.Row>
			</Table>
		</StyledSectionDiv>
	);
}

ContactsTable.propTypes = {
	search: PropTypes.string,
	id: PropTypes.string,
};

export default ContactsTable;
