import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Layout, ListItem, Select} from '@phonectas/unifon-ui-kit';
import {StyledLeftColumn, StyledRightColumn, StyledTextArea} from '../../styled';
import useUserStore from 'common/stores/user';
import useBMOStore from 'common/stores/BMO';
import LoadingPortal from 'common/components/Loader/components/LoaderPortal';
import {isStringNotEmpty, isValidPhone} from 'utils/validators';
import {
	StyledTextFieldMd,
	StyledTextFieldSm,
	StyledTextFieldXs,
} from './styled';
import EmailNotice from './EmailNotice';
import UploadFiles from 'common/components/UploadFiles';
import {createFormData} from 'utils/file';

function TicketForm() {
	const [t] = useTranslation();
	const {fetchAllCases, postTicketWithAttach} = useBMOStore();
	const {user} = useUserStore();
	const isLoading = useBMOStore((state) => state.BMO.isLoading);
	const [clearFiles, setClearFiles] = useState(false);
	// to be implementet
	// const ticketForm = useBMOStore((state) => state.BMO.ticketForm);
	// console.log(ticketForm.toJS());
	const DEFAULT_FORM = {
		description: '',
		severity: '',
		ticketType: 'Incident',
		name: '',
		customer: {
			reportedByName: user?.name || '',
			reportedByPhoneNumber: user?.mobile.replace('+47', '') || '',
		},
		faultyService: {
			serviceType: 'Mobil',
			identifier: '',
		},
		identifierType: {
			id: 'MD',
		},
		zendeskRef: '',
		notificationEmails: user?.email ? [user.email] : [],
		files: [],
	};
	const [form, setForm] = useState(DEFAULT_FORM);
	const handleChange = useCallback((name, value) => {
		setForm((prevForm) => ({
			...prevForm,
			[name]: value,
		}));
	}, []);
	const submit = async () => {
		try {
			const formData = createFormData(form);
			await postTicketWithAttach(formData, () => {
				setForm(DEFAULT_FORM);
				setClearFiles(true);
				fetchAllCases(0);
			});
		} catch (err) {
			console.error(err);
		}
	};
	const handleClear = () => {
		setForm(DEFAULT_FORM);
		setClearFiles(true);
	};

	useEffect(() => {
		if (clearFiles) {
			setClearFiles(false);
		}
	}, [clearFiles]);

	const mobileValidator = useCallback((val) => !val || isValidPhone(val), []);
	const emptyStrValidator = useCallback((val) => !val || isStringNotEmpty(val), []);

	const isDisabled = !(
		// mobileValidator(form.customer.reportedByPhoneNumber) &&
		mobileValidator(form.faultyService.identifier) &&
		isStringNotEmpty(form.description) &&
		isStringNotEmpty(form.severity) &&
		isStringNotEmpty(form.name) &&
		isStringNotEmpty(form.ticketType) &&
		isStringNotEmpty(form.customer.reportedByName) &&
		mobileValidator(form.customer.reportedByPhoneNumber) &&
		isStringNotEmpty(form.customer.reportedByPhoneNumber)
	);

	return (
		<Layout
			fullHeight
			fullWidth
			nowrap
			justifyContent="start"
			gap="lg"
		>
			<StyledLeftColumn
				direction="column"
				nowrap
				fullHeight
				gap="sm"
			>
				<Layout direction="row" fullWidth nowrap>
					<StyledTextFieldMd
						name="reportedByName"
						label={t('Reporter name')}
						fullWidth
						value={form.customer.reportedByName}
						onChange={(e) => handleChange('customer', {...form.customer, reportedByName: e.target.value})}
						validator={emptyStrValidator}
						maxLength={150}
					/>
					<StyledTextFieldSm
						name="reportedByPhoneNumber"
						label={t('Reporter number')}
						fullWidth
						inputProps={{maxLength: 8}}
						value={form.customer.reportedByPhoneNumber}
						onChange={(e) => handleChange('customer', {...form.customer, reportedByPhoneNumber: e.target.value})}
					/>
					<StyledTextFieldSm
						name="zendeskRef"
						label={t('Zendesk ref')}
						placeholder={t('Optional')}
						fullWidth
						inputProps={{maxLength: 6}}
						value={form.zendeskRef}
						onChange={(e) => handleChange('zendeskRef', e.target.value)}
					/>
				</Layout>
				<Layout direction="row" fullWidth nowrap>
					<Layout direction="column" gap="none">
						<StyledTextArea
							name="description"
							value={form.description}
							onChange={(e) => handleChange('description', e.target.value)}
							label={t('Description')}
							fullWidth
							maxLength={1000}
						/>
						<Layout
							direction="row"
							gap="none"
							justifyContent="end"
							style={{width: '450px'}}
						>
							{form.description.length}/1000
						</Layout>
					</Layout>
				</Layout>
				<Layout direction="row" fullWidth nowrap>
					<Select
						name="severity"
						value={form.severity}
						onChange={(value) => handleChange('severity', value)}
						label={t('Severity')}
						placeholder={t('Select')}
					>
						<ListItem
							value={'A-feil'}
							primaryText={t('A-Error')}
							selected={form.severity === 'A-feil'}
							onSelect={(value) => handleChange('severity', value)}
						/>
						<ListItem
							value={'B-feil'}
							primaryText={t('B-Error')}
							selected={form.severity === 'B-feil'}
							onSelect={(value) => handleChange('severity', value)}
						/>
					</Select>
					<Select
						name="name"
						value={form.name}
						onChange={(value) => handleChange('name', value)}
						label={t('Problem Type')}
						placeholder={t('Select')}
					>
						<ListItem
							value={'Ingen tjeneste'}
							primaryText={'Ingen tjeneste'}
							selected={form.ticketType === 'Ingen tjeneste'}
							onSelect={(value) => handleChange('name', value)}
						/>
						<ListItem
							value={'Redusert kvalitet'}
							primaryText={'Redusert kvalitet'}
							selected={form.ticketType === 'Redusert kvalitet'}
							onSelect={(value) => handleChange('name', value)}
						/>
						<ListItem
							value={'Tilleggstjenester'}
							primaryText={'Tilleggstjenester'}
							selected={form.ticketType === 'Tilleggstjenester'}
							onSelect={(value) => handleChange('name', value)}
						/>
						<ListItem
							value={'Leveransesvikt'}
							primaryText={'Leveransesvikt'}
							selected={form.ticketType === 'Leveransesvikt'}
							onSelect={(value) => handleChange('name', value)}
						/>
					</Select>
				</Layout>
				<Layout direction="row" fullWidth nowrap>
					<StyledTextFieldMd
						name="identifier"
						label={t('Mobile used')}
						fullWidth
						inputProps={{maxLength: 8}}
						value={form.faultyService.identifier}
						onChange={(e) => handleChange('faultyService', {...form.faultyService, identifier: e.target.value})}
					/>
					<StyledTextFieldXs
						name="serviceType"
						label={t('Service-Type')}
						fullWidth
						disabled
						value={form.faultyService.serviceType}
					/>
					<StyledTextFieldXs
						name="ticketType"
						label={t('Ticket-Type')}
						fullWidth
						disabled
						value={form.ticketType}
					/>
				</Layout>
				<UploadFiles
					onClear={clearFiles}
					onChange={handleChange}
				/>
				<Layout
					fullWidth
					nowrap
					style={{justifyContent: 'start', marginTop: '10px'}}
				>
					<Button
						variant="primary"
						disabled={isDisabled}
						onClick={submit}
					>
						{t('Submit')}
					</Button>
					<Button variant="secondary" onClick={handleClear}>{t('Clear form')}</Button>
				</Layout>
			</StyledLeftColumn>
			{/* If more options comes from Telenor */}
			<StyledRightColumn
				direction="column"
				fullHeight
				nowrap
				gap="sm"
			>
				<Layout direction="row" fullWidth nowrap>
					<EmailNotice
						form={form}
						onChange={handleChange}
						user={user}
					/>
				</Layout>
				<Layout direction="row" fullWidth nowrap></Layout>
				<Layout direction="row" fullWidth nowrap></Layout>
			</StyledRightColumn>
			<LoadingPortal isLoading={isLoading} />
		</Layout>
	);
}

export default TicketForm;
