import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {CloseIcon, Divider, IconButton, Layout, ShevronDownIcon, Text} from '@phonectas/unifon-ui-kit';
import {serviceStatuses, nullCheck} from 'utils/div';
import {DATE_FORMATS, getDateByCustomFormat} from 'utils/date';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import CustomList from 'common/components/CustomList';
import useThreeSixtyStore from 'common/stores/ThreeSixty';

function UserDialogContent({onClose, data}) {
	const [t] = useTranslation();
	const {fetchUserParentId} = useThreeSixtyStore();
	const parentData = useThreeSixtyStore((state) => state.accountTables?.parentId);

	useEffect(() => {
		if (data?.cdr_children_count > 0) {
			fetchUserParentId(10, data.account_id, '', data.cdr_subscription_id);
		}
	}, [data?.cdr_subscription_id]);

	return (
		<>
			<Layout nowrap alignItems="start" fullWidth justifyContent="space-between">
				<Layout nowrap fullWidth alignItems="center">
					<Layout direction="column" gap="none">
						<Layout alignItems="center" gap="sm" fullWidth>
							<Text variant="h1">
								{data.name}
							</Text>
						</Layout>
					</Layout>
				</Layout>
				<IconButton onClick={onClose}><CloseIcon /></IconButton>
			</Layout>
			<Divider fullWidth />
			<Layout direction="column" gap="none" nowrap fullWidth justifyContent="start">
				<CustomList label={t('Phone')} primaryText={nullCheck(data?.phone_number)} />
				<CustomList label={t('Email')} primaryText={nullCheck(data.email)} />
				<CustomList label={t('PIN')} primaryText={nullCheck(data?.pin)} />
				<CustomList label={t('PUK')} primaryText={nullCheck(data?.puk)} />
				<CustomList label={t('Binding')} primaryText={getDateByCustomFormat(
					data?.lockin_end, DATE_FORMATS.DD_MM_YYYY,
				)} />
				<CustomList label={t('status')} primaryText={serviceStatuses(data?.status)} />
				<CustomList label={t('SIM')} primaryText={nullCheck(data?.icc)} />
				<CustomList label={t('Product')} primaryText={nullCheck(data?.sim_type)} />
				<CustomList label={t('SIM type')} primaryText={nullCheck(data?.subscription_type)} />
				<CustomList label={t('Used')} primaryText={nullCheck(data?.used)} />
				<CustomList label={t('Porting date')} primaryText={
					data?.port_date ? getDateByCustomFormat(data?.port_date, DATE_FORMATS.DD_MM_YYYY_HH_mm) :
						getDateByCustomFormat(data?.start_date, DATE_FORMATS.DD_MM_YYYY)
				} />
			</Layout>
			{
				data?.cdr_children_count > 0 && (
					<Accordion sx={{width: '100%', marginTop: '10px'}}>
						<AccordionSummary
							expandIcon={<ShevronDownIcon />}
						>
							<Text variant="h5">
								{t('Additional SIMs')}: {data?.cdr_children_count}
							</Text>
						</AccordionSummary>
						<AccordionDetails>
							{
								parentData?.customer_users?.map((item, index) => (
									<Layout
										key={index}
										direction="column"
										gap="md"
										fullWidth
										style={{
											borderLeft: '1px solid rgb(182, 183, 204)',
											borderBottom: '1px solid rgb(182, 183, 204)',
											padding: '10px',
											marginBottom: '5px',
										}}
									>
										<Layout
											nowrap
											direction="column"
											fullWidth
											style={{position: 'relative'}}
										>
											<Text variant="h5">{`SIM: ${item.icc}`}</Text>
											<Layout nowrap direction="row" fullWidth>
												<CustomList compact label={t('PIN')} primaryText={item.pin} />
												<CustomList compact label={t('PUK')} primaryText={item.puk} />
												<CustomList compact label={t('Binding')} primaryText={getDateByCustomFormat(
													item?.lockin_end, DATE_FORMATS.DD_MM_YYYY,
												)} />
											</Layout>
											<Layout nowrap direction="row" fullWidth>
												<CustomList compact label={t('Status')} primaryText={serviceStatuses(item.status)} />
												<CustomList compact label={t('Product')} primaryText={item.sim_type} />
												<CustomList compact label={t('SIM type')} primaryText={item?.subscription_type} />
											</Layout>
											<Layout nowrap direction="row" fullWidth>
												<CustomList compact label={t('Used')} primaryText={item?.used} />
											</Layout>
										</Layout>
										{/* <Divider style={{margin: '10px 0px', color: 'black'}} fullWidth /> */}
									</Layout>
								))
							}
						</AccordionDetails>
					</Accordion>
				)
			}
		</>
	);
}

UserDialogContent.propTypes = {
	onClose: PropTypes.func,
	data: PropTypes.object,
};

export default React.memo(UserDialogContent);
