import React from 'react';

import NewCallIcon from 'common/components/icons/NewCallIcon';
import NewEmailIcon from 'common/components/icons/NewEmailIcon';
import NewSmsIcon from 'common/components/icons/NewSmsIcon';
import RatorEventIcon from 'common/components/icons/RatorEventIcon';
import ThreeSixtyIcon from 'common/components/icons/ThreeSixtyIcon';
import {CommentIcon} from '@phonectas/unifon-ui-kit';

function getIconByType(type) {
	switch (type) {
	case 'BN_CALL_LOG':
		return <NewCallIcon />;
	case 'email':
		return <NewEmailIcon />;
	case 'sms':
		return <NewSmsIcon />;
	case 'rator':
		return <RatorEventIcon />;
	case 'ERATE_SYSTEM_SMS':
		return <RatorEventIcon />;
	case 'ERATE_SYSTEM_CALL':
		return <RatorEventIcon />;
	case 'THREESIXTY_NEW_CUSTOMER_RESP':
		return <ThreeSixtyIcon editSize="26"/>;
	case 'THREESIXTY_COMMENT':
		return <CommentIcon />;
	default:
		return null;
	}
}

export default getIconByType;
