import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom/dist';
import CasesTable from '../components/cases/CasesTable';
import AuthService from 'services/auth';

import {useSmallScreen} from 'common/hooks/useScreenSize';
import useBMOStore from 'common/stores/BMO';
// import useUserStore from 'common/stores/user';
import LoaderPortal from 'common/components/Loader/components/LoaderPortal';
import Case from './Case';
import AdaptiveDrawer from 'common/components/AdaptiveDrawer';
import {Divider, Layout, Page} from '@phonectas/unifon-ui-kit';
// import {LIMIT_PAYLOAD} from 'services/constants';

function CasesTab() {
	const location = useLocation();
	const isSmallScreen = useSmallScreen();
	const {fetchAllCases, searchCases, getStatuses} = useBMOStore();
	const BMO = useBMOStore((state) => state.BMO);
	// const user = useUserStore((state) => state.user?.name);
	const [filteredData, setFilteredData] = useState();
	const isLoading = useBMOStore((state) => state.BMO.isLoading);
	const activeCase = BMO.ticketById.id;

	useEffect(() => {
		setFilteredData(BMO.ticketsList);
	}, [setFilteredData, BMO.ticketsList]);

	useEffect(() => {
		if (AuthService.isValidToken()) {
			fetchAllCases(0);
			getStatuses();
			// to be implementet
			// fetchAllCases(LIMIT_PAYLOAD);
		}
	}, [fetchAllCases]);
	const onSearch = useCallback((query) => {
		// console.log(query, 'from casesTab');
		if (query !== '') {
			searchCases(query);
		}
	}, [searchCases]);

	const caseContent = useMemo(() => {
		return (
			location?.search !== '' && (
				<>
					<Layout direction="column" fullWidth nowrap>
						<Page.Section fullHeight fullWidth>
							{
								filteredData !== undefined && (
									<Case data={filteredData} />
								)
							}
						</Page.Section>
					</Layout>
				</>
			)
		);
	}, [filteredData, location?.search, fetchAllCases]);

	return (
		<Layout direction="row" nowrap fullHeight fullWidth style={{overflow: 'hidden'}}>
			<Layout direction="column" style={{minWidth: '65%'}} fullWidth nowrap>
				<Page.Section style={{overflow: 'hidden'}} fullWidth fullHeight>
					{
						filteredData !== undefined && (
							<CasesTable
								data={filteredData}
								activeId={location.pathname}
								onSearch={onSearch}
							/>
						)
					}
				</Page.Section>
			</Layout>
			{
				activeCase !== undefined && (
					<Divider vertical dark />
				)
			}
			{
				isSmallScreen && activeCase !== undefined ?
					<AdaptiveDrawer setWidth={900}><>{caseContent}</></AdaptiveDrawer> :
					<>
						{caseContent}
					</>
			}
			<LoaderPortal isLoading={isLoading} />
		</Layout>
	);
}

export default CasesTab;
